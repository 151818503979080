<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" v-if="info && info.user_type==1">
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
<!--    <el-tabs v-model="search.c_type" @tab-click="changeType">-->
<!--      <el-tab-pane label="每日值班表" name="1"></el-tab-pane>-->
<!--      <el-tab-pane label="每周值班表" name="2"></el-tab-pane>-->
<!--      <el-tab-pane label="每月值班表" name="3"></el-tab-pane>-->
<!--    </el-tabs>-->
<!--    <div class="mg-bt-10">-->
<!--      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">创建值班表</el-button>-->
<!--    </div>-->
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="store_title"
          label="门店"
          min-width="130">
      </el-table-column>
      <el-table-column
          prop="c_type"
          label="类型"
          min-width="100"
          align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.c_type==2"><span class="red">每日财务登记表</span></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="week"
          label="周期"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.c_type==2">
            {{scope.row.start_date}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="登记表状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">未开始</span>
          <span v-if="scope.row.status==1">进行中</span>
          <span v-if="scope.row.status==2">已完成</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="goDetail(scope.row)">详情</el-button>
          <el-popconfirm v-if="scope.row.status==0"
              title="确认删除本次记录吗？"
              @confirm="delWeek(scope.row.id)"
          >
            <el-button slot="reference" type="text" style="color: red; margin-left: 10px;" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="创建登记表"
        :visible.sync="dialogPayVisible"
        :destroy-on-close="true"
        width="30%">
      <create-store-four :shopList="storeList" @callFunc="createOk()"></create-store-four>
    </el-dialog>
  </div>
</template>
<script>

import CreateStoreFour from "@/components/CreateStoreFour";
export default {
  components: {
    CreateStoreFour
  },
  data() {
    return {
      info: false,
      dialogPayVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        store: '',
        c_type: '2'
      },
      tableData: [],
      storeList: []
    };
  },
  created() {
    this.getMyInfo()
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeType(e) {
      this.search.current_page = 1;
      this.getList()
    },
    addGroup() {
      this.dialogPayVisible = true
    },
    createOk() {
      this.dialogPayVisible = false
      this.getList()
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        store: that.search.store,
        c_type: that.search.c_type
      }
      this.$api.operate.financeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    delWeek(id) {
      this.$api.operate.financeDel({id:id}, res => {
        if(res.errcode==0) {
          this.success('删除成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    goDetail(item) {
      // if(item.c_type!=2) {
      //   this.fail("值班表开发中")
      //   return false
      // }
      this.$router.push({name: 'operate_finance_show', query: {id: item.id}})
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
